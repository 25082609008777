export default function (config: any) {
  const toast = useToast()

  const object = {
    title: config?.title ?? '',
    color: config?.color ?? 'primary',
    timeout: config?.timeout ?? 1500,
  }

  if (config?.description && !_isEmpty(config?.description))
    _set(object, 'description', config?.description)

  if (config?.avatar && !_isEmpty(config?.avatar))
    _set(object, 'avatar', config?.avatar)

  if (config?.icon && !_isEmpty(config?.icon))
    _set(object, 'icon', config?.icon)

  toast.add(object)
}
